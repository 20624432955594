import React from "react";
import Layout from "../components/layout";
import WorkoutsScreen from "../screens/WorkoutsScreen";
import SEO from "../components/seo";
import { graphql } from "gatsby";

const workouts = ({ data }) => {
  const videos = data.allYoutubeVideo.edges.map(({ node }) => node);
  console.log("videos: ", videos);
  return (
    <Layout>
      <SEO title="Awesome Workouts You Can Do Anywhere" />
      <WorkoutsScreen workouts={[...videos, ...videos]} />
    </Layout>
  );
};

export const query = graphql`
  query YouTubeWorkoutsQuery {
    allYoutubeVideo {
      edges {
        node {
          id
          title
          description
          videoId
          publishedAt
          privacyStatus
          thumbnail {
            url
            width
            height
          }
        }
      }
    }
  }
`;

export default workouts;
