import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const mainHeight = {
  desktop: 721,
  medium: 640,
  tablet: 512,
  mobile: 408,
  small: 320,
  xsmall: 270
};

const WorkoutsScreenEl = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${mainHeight.desktop}px;
  padding: 0 72px;
  margin: 0 24px 124px;

  .active-vid-window {
    flex-grow: 1;

    /* margin-right: 305px; */

    iframe {
      border: 1px solid #ddd;
      margin: 0;
      padding: 0;
    }
  }

  .view-vids {
    position: relative;
    flex-grow: 0;
    background: white;
    border: 1px solid #ddd;

    .view-vids-list {
      max-height: 721px;
      overflow: scroll;
    }

    .view-vids-title-wrapper {
      position: relative;
      width: 100%;

      .view-vids-title {
        position: absolute;
        width: 100%;
        top: 0;
        background: white;
        padding: 16px;
        box-shadow: 0 3px 6px 4px rgba(0, 0, 0, 0.1);
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    padding: 0;
    height: ${mainHeight.medium}px;

    .view-vids {
      .view-vids-list {
        height: ${mainHeight.medium}px;
      }
    }
  }

  @media screen and (max-width: 1190px) {
    padding: 0;
    height: ${mainHeight.tablet}px;

    .view-vids {
      .view-vids-list {
        height: ${mainHeight.tablet}px;
      }
    }
  }

  @media screen and (max-width: 996px) {
    padding: 0;
    height: ${mainHeight.mobile}px;

    .view-vids {
      .view-vids-list {
        height: ${mainHeight.mobile}px;
      }
    }
  }

  /* At this point, we stack */
  @media screen and (max-width: 916px) {
    padding: 0;
    height: ${mainHeight.tablet}px;
    flex-direction: column;
    height: max-content;

    .active-vid-window {
      iframe {
        height: ${mainHeight.tablet}px;
      }
    }

    .view-vids {
      .view-vids-list {
        max-height: auto;
        height: auto;
        display: flex;
      }
    }
  }

  @media screen and (max-width: 596px) {
    .active-vid-window {
      iframe {
        height: ${mainHeight.small}px;
      }
    }
  }

  @media screen and (max-width: 424px) {
    .active-vid-window {
      iframe {
        height: ${mainHeight.xsmall}px;
      }
    }
  }

  /* @media screen and (max-width: 1190px) {
    padding: 0;
    height: ${mainHeight.tablet}px;

    .view-vids {
      .view-vids-list {
        height: ${mainHeight.tablet}px;
      }
    }
  } */
`;

const WorkoutEl = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 24px;
  width: 305px;
  box-sizing: border-box;

  &:first-child {
    margin-top: 42px;
  }

  &:last-child {
    border-bottom: none;
  }

  .workout-vid-thumbnail {
    width: 256px;
    height: 153px;
    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 12px;
    cursor: pointer;
  }

  h3 {
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
  }

  @media screen and (max-width: 916px) {
    height: max-content;
    margin-top: 42px;
    border-right: 1px solid #ddd;

    &:last-child {
      border-bottom: 1px solid #ddd;
      border-right: none;
    }
  }
`;

const WorkoutsScreen = ({ workouts }) => {
  const [{ videoId: selectedVideoId }, setselectedVideo] = useState(
    workouts[0]
  );

  return (
    <WorkoutsScreenEl>
      <section className="active-vid-window">
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${selectedVideoId}`}
        />
      </section>
      <section className="view-vids">
        <div className="view-vids-title-wrapper">
          <div className="view-vids-title">Workout Videos</div>
        </div>
        <div className="view-vids-list">
          {workouts.map(workout => {
            console.log("workout length is: ", workout.title);
            return (
              <WorkoutEl
                key={workout.id}
                backgroundImage={workout.thumbnail.url}
              >
                <div
                  className="workout-vid-thumbnail"
                  onClick={() => setselectedVideo(workout)}
                />
                <h3 onClick={() => setselectedVideo(workout)}>
                  {`${workout.title.split(" // ")[0].slice(0, 27)}`}
                  {`${workout.title.split(" // ")[0].length > 28 ? "..." : ""}`}
                </h3>
              </WorkoutEl>
            );
          })}
        </div>
      </section>
    </WorkoutsScreenEl>
  );
};

WorkoutsScreen.propTypes = {
  workouts: PropTypes.arrayOf(
    PropTypes.shape({
      videoId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      videoId: PropTypes.string.isRequired,
      publishedAt: PropTypes.string.isRequired,
      privacyStatus: PropTypes.string.isRequired,
      thumbnail: PropTypes.shape({
        url: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired
      })
    }).isRequired
  ).isRequired
};

export default WorkoutsScreen;
